import React from 'react';
import { Box, Heading, Text, Stack, Link } from "@chakra-ui/react";
import { Link as GatsbyLink } from 'gatsby';
import BackgroundImage from '../../../images/product/snorkel_svg.svg';

export default function HowForIndividualsSection() {
  return <Box bgGradient="linear(to-b, #495AE2, #23239F)" p={["2rem", "2rem", "4rem"]} color="white">
    <Stack direction={["column", null, null, "row"]} spacing="4rem" py="75px">
      <Box w={['100%', null, null, '70%', '40%']}>
        <Stack spacing={8}>
          <Heading size="2xl" data-sal="slide-right" data-sal-duration="1000">So how does it work for individuals?</Heading>
          <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500">At this stage, to join makeripples you will have to be invited to a stream by an organisation that uses us (be sure to spread the word to organisations about us if they’re not already signed up <span role="img" aria-label="cheeky">😘</span>). We are currently working on a public directory to connect you with organisations in your community with access to their calls to action and public streams. If this sounds like something you want, you can <Link href="https://forms.gle/pVeWjmGHq1it16Vt9" as={GatsbyLink} fontWeight="bold" isExternal>register your interest here!</Link></Text>
          <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500">Do you have a cause/message you are an expert on? Do you want to advocate for it and start building ripples? An individual can also sign up as an organisation, just <Link to="/contact" as={GatsbyLink} fontWeight="bold">get in touch with us</Link> for options.</Text>
        </Stack>
      </Box>
      <Box minHeight="400px" display="block" w={['100%', null, null, '60%']} h={['500px', null, null, 'inherit']} backgroundImage={`url(${BackgroundImage})`} backgroundPosition={["center", null, null, null, "right center"]} backgroundSize="contain" backgroundRepeat="no-repeat" data-sal="slide-left" data-sal-duration="1000">
      </Box>
    </Stack>
  </Box>
}