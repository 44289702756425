import * as React from "react"
import { Helmet } from "react-helmet";
import Layout from '../components/layout'
import MakeRipplesSection from "../containers/sections/make-ripples-section";
import OtherPagesSection from "../containers/sections/other-pages-section";
import HowCreateSection from "../containers/sections/product/how-create-section";
import HowForIndividualsSection from "../containers/sections/product/how-for-individuals";
import HowMeasureSection from "../containers/sections/product/how-measure-section";
import HowShareSection from "../containers/sections/product/how-share-section";
import HowSummarySection from "../containers/sections/product/how-summary-section";

const Product = ({ location }) => {
  return <Layout activePage={location.pathname}>
    <Helmet>
      <title>makeripples - Product</title>
    </Helmet>
    <HowSummarySection />
    <HowCreateSection />
    <HowShareSection />
    <HowMeasureSection />
    <HowForIndividualsSection />
    <OtherPagesSection activePage={location.pathname} />
    <MakeRipplesSection />
  </Layout>
}

export default Product;