import React from 'react';
import { Box, Heading, Text, Stack } from "@chakra-ui/react";
import BackgroundImage from '../../../images/product/measure-group.png';

export default function HowMeasureSection() {
  return <Box p={["2rem", "2rem", "4rem"]} bgColor="white">
    <Stack direction={["column", null, null, "row"]} spacing="4rem" py="75px">
      <Box w={['100%', null, null, '70%', '40%']}>
        <Stack spacing={8}>
          <Heading size="2xl" data-sal="slide-right" data-sal-duration="1000">Measuring your ripple effect.</Heading>
          <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500">Every time someone does something on our platform, we measure it. Our plan is to then interpret it and tell you what it means for you. You can check out your progress right in the makeripples platform or request an impact report at any time for a more in-depth look (note that this is functionality that we are still building). Our plan is to have no surveys or clunky methods of collecting data to see how you’re doing, we measure as we go.</Text>
        </Stack>
      </Box>
      <Box minHeight="400px" display="block" w={['100%', null, null, '60%']} h={['500px', null, null, 'inherit']} backgroundImage={`url(${BackgroundImage})`} backgroundPosition={["center", null, null, null, "right center"]} backgroundSize="contain" backgroundRepeat="no-repeat" data-sal="slide-left" data-sal-duration="1000">
      </Box>
    </Stack>
  </Box>
}