import React from 'react';
import { Box, Heading, Text, Stack} from "@chakra-ui/react";
import BackgroundImage from '../../../images/backgrounds/diving.svg';

export default function HowSummarySection() {
  return <Box backgroundImage={`url(${BackgroundImage})`} backgroundPosition="bottom right" backgroundSize={["80% auto", null, '60% auto', '800px']} backgroundRepeat="no-repeat" py={150} minHeight="100vh">
    <Box p={["2rem", "2rem", "4rem"]}>
      <Box w={['100%', '100%', '70%', '50%']}>
        <Stack spacing={8}>
          <Heading size="2xl" data-sal="slide-right" data-sal-duration="1000">So how does it work?</Heading>
          <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500">Makeripples is a web-based digital platform. We think of it as an ‘action management tool’. It allows organisations to create action-packed online programmes and then invite people to take part in them through their own dashboards. We then measure the impact these programmes have and what it means every time you make a ripple. </Text>
        </Stack>
      </Box>
    </Box>
  </Box>
}


