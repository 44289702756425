import React from 'react';
import { Box, Heading, Text, Stack } from "@chakra-ui/react";
import BackgroundImage from '../../../images/product/share-group.png';

export default function HowMeasureSection() {
  return <Box p={["2rem", "2rem", "4rem"]}>
    <Stack direction={["column", null, null, "row"]} spacing="4rem" py="75px">
      <Box w={['100%', null, null, '70%', '40%']}>
        <Stack spacing={8}>
          <Heading size="2xl" data-sal="slide-right" data-sal-duration="1000">Getting your audience to make ripples.</Heading>
          <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500">Streams are specific to the members you invite to them. You can sign them up using their emails. You can do this through a kiosk at an event, through a link on your website, social media group, packaging, billboard, car, mum’s car or even on your pet iguana.</Text>
          <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500">We want to start a dialogue. This ain’t no monthly newsletter they’re signing up for, this is a two-way street. Your community wants to communicate with you and we will help them do it. Through their very own dashboards, members can make their way through your stream, interact with ripples, share stories and ask questions.</Text>
        </Stack>
      </Box>
      <Box minHeight="400px" display="block" w={['100%', null, null, '60%']} h={['500px', null, null, 'inherit']} backgroundImage={`url(${BackgroundImage})`} backgroundPosition={["center", null, null, null, "right center"]} backgroundSize="contain" backgroundRepeat="no-repeat" data-sal="slide-left" data-sal-duration="1000">
      </Box>
    </Stack>
  </Box>
}