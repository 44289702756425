import React from 'react';
import { Box, Heading, Text, Stack } from "@chakra-ui/react";
import BackgroundImageStreams from '../../../images/product/small-streams.png';
import BackgroundImageRipples from '../../../images/product/ripples-group.png';

export default function HowCreateSection() {
  return <Box p={["2rem", "2rem", "4rem"]} bgColor="white">
    <Stack>
      <Stack direction={["column", null, null, "row"]} spacing="4rem" py="75px">
        <Box w={['100%', null, null, '70%', '40%']}>
          <Stack spacing={8}>
            <Heading size="2xl" data-sal="slide-right" data-sal-duration="1000">What is a ripple?</Heading>
            <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500">A ripple is our term for an action. It could be anything! A call to action to donate, take up dancing, volunteer, plant a tree, sponsor a polar bear, read an article, watch a video, attend a webinar, save a banana, commit to a lifestyle change, sign a petition or write a submission. Anything you need to get you closer to your goal. </Text>
            <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500">You create ripples on the organiser dashboard and members can interact with it on their own dashboards.</Text>
            <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500">Ripples are released to your members in streams.</Text>
          </Stack>
        </Box>
        <Box minHeight="400px" display="block" w={['100%', null, null, '60%']} h={['500px', null, null, 'inherit']} backgroundImage={`url(${BackgroundImageStreams})`} backgroundPosition={["center", null, null, null, "right center"]} backgroundSize="contain" backgroundRepeat="no-repeat" data-sal="slide-left" data-sal-duration="1000">
        </Box>
      </Stack>
      <Stack direction={["column", null, null, "row"]} spacing="4rem" py="75px">
        <Box w={['100%', null, null, '70%', '40%']}>
          <Stack spacing={8}>
            <Heading size="2xl" data-sal="slide-right" data-sal-duration="1000">What is a stream?</Heading>
            <Text data-sal="slide-right" data-sal-duration="1000" data-sal-delay="500">Streams are made up of lots of ripples- so lots of action, and they are geared to an audience. A stream could be built to train volunteers, run an educational programme, set daily/weekly/monthly challenges, run a series of workshops interspersed with action, follow up a product-purchase with resources or guides to help facilitate behaviour change. Anything you need.</Text>
          </Stack>
        </Box>
        <Box minHeight="500px" display="block" w={['100%', null, null, '60%']} h={['500px', null, null, 'inherit']} backgroundImage={`url(${BackgroundImageRipples})`} backgroundPosition={["center", null, null, null, "right center"]} backgroundSize="contain" backgroundRepeat="no-repeat" data-sal="slide-left" data-sal-duration="1000">
        </Box>
      </Stack>
    </Stack>
  </Box>
}